<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="记录类型" :rules="[{ required: true, message: '请选择'}]">
        <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
          <el-option v-for="o in type_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item label="金额" :rules="[{ required: true, message: '请输入金额'}]">
            <el-input-number v-model="form.fee" controls-position="right" :min="0"></el-input-number> <span style="margin-left: 10px;">万元</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计算类型" :rules="[{ required: true, message: '请选择'}]">
            <el-select v-model="form.fee_calc_type" placeholder="请选择" style="width: 100%">
              <el-option v-for="o in fee_calc_type_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="备注" :rules="[{ required: true, message: '请输入备注'}]">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      type_options: [],

      // 1.加总额 2.减总额 3.加使用 4.减使用

      fee_calc_type_options: [
        { label: '加总额', value: 1 },
        { label: '减总额', value: 2 },
        { label: '加使用', value: 3 },
        { label: '减使用', value: 4 },
      ],
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;

      const sys_dict = (await this.$api.execute('sys_dict.list', { code: ['zjjllx_options'] })).list;

      this.type_options = _(_.get(_.find(sys_dict, { code: 'zjjllx_options'}), 'children'))
        .map(o => ({ label: o.name, value: o.code || o.name })).value();

      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('ycjh_cms.cash_pool.log.create', _.assign({}, this.form, {
        }));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$notify({
          type: 'error',
          message: e.message
        });
      }
    }
  }
}
</script>