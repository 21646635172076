<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="整合至" :rules="[{ required: true, message: '请选择'}]">
        <el-select v-model="form.cash_pool_2_id" placeholder="请选择" style="width: 100%">
          <el-option v-for="o in cash_pool_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="整合金额" :rules="[{ required: true, message: '请输入整合金额'}]">
        <el-input-number v-model="form.fee" controls-position="right" :min="0"></el-input-number> <span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="整合原因" :rules="[{ required: true, message: '请输入整合原因'}]">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      zjnf_options: [],
      cash_pool_options: [],
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;

      this.$api.execute('ycjh_cms.cash_pool.list', { }).then(res => {
        this.form = _.assign({}, this.form, { sqzjc: '' })
        this.cash_pool_options = _(res.list).filter(o => o.id !== this.form.cash_pool_id).map(o => ({ label: o.name, value: o.id })).value()
      })

      this.visible = true;
    },

    async confirmForm() {
      try {
        const cash_pool_name = _.get(_.find(this.cash_pool_options, { value: this.form.cash_pool_2_id }), 'label');
        await this.$api.execute('ycjh_cms.cash_pool.log.create', _.assign({}, this.form, {
          fee_calc_type: 3,
          type: '资金整合',
        }));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$notify({
          type: 'error',
          message: e.message
        });
      }
    }
  }
}
</script>