<template>
  <div style="padding: 10px;">
    <el-form ref="form" :model="form" label-width="140px">
      <el-form-item label="资金名称">
        {{ info.name }}
      </el-form-item>

      <el-form-item label="资金年份">
        {{ info.name }}
      </el-form-item>

      <el-form-item label="金额总额">
        {{ info.fee }}<span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="已使用资金">
        {{ info.used_fee }}<span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="剩余资金">
        {{ remain_fee }} <span style="margin-left: 10px;">万元</span> <el-button v-if="remain_fee > 0" size="mini" style="margin-left: 10px;" @click="showForm">资金整合</el-button>
      </el-form-item>
    </el-form>

    <edit-form ref="editForm" />
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import EditForm from './EditForm';

export default {
  components: {
    EditForm
  },
  props: {
    info: {
      type: Object,
      default: () => null,
    }
  },
  computed: {
    remain_fee() {
      return BigNumber(this.info.fee).minus(this.info.used_fee)
    }
  },
  data() {
    return {
    }
  },
  methods: {
    showForm() {
      this.$refs.editForm.show({
        type: '资金整合',
        cash_pool_id: this.info.id,
      }, { title: '资金整合', success: () => {
        this.$emit('info-change')
      } })
    }
  }
}
</script>