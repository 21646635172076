<template>
  <a class="el-button el-button--text" style="padding: 0;" target="_blank" :href="options.href"><slot /></a>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
