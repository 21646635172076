<template>
  <div v-if="pageConfig && info" style="background: #fff; margin-top: 15px;">
    <el-tabs v-model="tabValue" style="margin: 0 15px 0 15px">
      <el-tab-pane v-for="o in tabs" :key="o.value" :label="o.label" :name="o.value" />
    </el-tabs>

    <component :is="tab.component" :options="tab.componentOptions" v-if="tab.component" :page-key="tab.value" :info="info" @info-change="refresh" />
    <div v-else-if="tab.value === 'config'" style="padding: 15px;">
      <div v-for="o in tabArray" :key="o.value" style="margin-bottom: 5px;">
        <el-checkbox v-model="form[o.value].show" />
        <el-input v-model="form[o.value].label" style="width: 200px;margin-left: 10px;" :placeholder="form[o.value].default_label" size="mini" />
      </div>

      <div style="margin-top: 10px;">
        <el-button type="primary" size="mini" @click="saveConfig">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import tab_info from './tab_info'
import tab_file from './tab_file'
import tab3 from './tab3'

export default {
  components: { tab_info, tab_file, tab3 },
  data() {
    return {
      tabArray: [
        { label: '基本资料', value: 'tab_1', component: 'tab_info' },
        { label: '资金文件', value: 'tab_2', component: 'tab_file', componentOptions: { type: '资金文件' } },
        // { label: '资金文件2', value: 'tab_22', component: 'tab_file', componentOptions: { type: '资金文件2' } },
        { label: '资金记录', value: 'tab_3', component: 'tab3' },
        // { label: '操作记录', value: 'tab_8', component: 'tab6' },
      ],
      tabValue: 'tab_1',
      tabCount: {},
      is_admin: false,

      pageConfig: null,
      canConfig: false,

      form: { },

      info: null,
    }
  },
  computed: {

    tabs() {
      let ret = _(this.tabArray).reject(o => this.getPageConfig(`tab.${o.value}.hide`, o.hide)).map(o => _.assign({}, o, {
        label: this.getPageConfig(`tab.${o.value}.label`, o.label)
      })).value()
      if (this.is_admin && this.canConfig) {
        ret = ret.concat([
          { label: '配置', value: 'config' }
        ])
      }
      return ret
    },
    tab() {
      return _.find(this.tabs, { value: this.tabValue })
    }
  },
  watch: {
    tabValue(value) {
      if (value === 'config') {
        this.form = _(this.tabArray).mapKeys('value').mapValues(o => _.assign({}, o, {
          default_label: o.label,
          label: this.getPageConfig(`tab.${o.value}.label`),
          show: !this.getPageConfig(`tab.${o.value}.hide`, o.hide)
        })).value()
      }
    }
  },

  created() {

    if(this.canConfig) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.is_admin = true; // !!_.find(userInfo.roles, { name: '超级管理员' })

      this.$api.executeArray(
        ['sys_params.list', { code: `pageConfig_${this.$route.path}` }],
      ).then(([
        sys_params_list_res,
      ]) => {
        this.pageConfig = JSON.parse((sys_params_list_res.list[0] ? sys_params_list_res.list[0].value : null) || '{}')
        if (_.isArray(this.pageConfig.tab)) this.pageConfig.tab = {}
        this.pageConfig.tab = _.pick(this.pageConfig.tab, _.map(this.tabArray, 'value'))
        this.tabValue = _.find(this.tabs, { value: this.tabValue }) ? this.tabValue : _.get(this.tabs, '[0].value')
      })
    } else {
      this.pageConfig = {}
      this.pageConfig.tab = _.pick(this.pageConfig.tab, _.map(this.tabArray, 'value'))
      this.tabValue = _.find(this.tabs, { value: this.tabValue }) ? this.tabValue : _.get(this.tabs, '[0].value')
    }

    this.refresh()
    
  },

  methods: {
    setPageConfig(path, value) {
      this.pageConfig = _.set(_.clone(this.pageConfig), path, value)
      this.$api.execute('sys_params.replace', { code: `pageConfig_${this.$route.path}`, value: JSON.stringify(this.pageConfig) })
    },
    getPageConfig(path, def) {
      const value = _.get(this.pageConfig, path)
      if (value === undefined) return def
      return value
    },

    saveConfig() {
      this.pageConfig = _.assign({}, this.pageConfig, {
        tab: _(_.assign({}, this.pageConfig.tab, _.mapValues(this.form, o => _.assign({}, _.pick(o, ['label']), { hide: !o.show }))))
          .mapValues(o => _.pickBy(o, value => value !== '' && value !== false && value !== undefined))
          .pickBy(o => _.size(o))
          .value()
      })
      this.$api.execute('sys_params.replace', { code: `pageConfig_${this.$route.path}`, value: JSON.stringify(this.pageConfig) })
    },

    refresh() {
      
      const { id } = this.$route.params;
      this.$api.execute('ycjh_cms.cash_pool.list', { id }).then(res => {
        if(res.count === 1) {
          this.info = res.list[0];

          console.log(this.info)
        }
      })
    }
  }
}
</script>

<style>

</style>
